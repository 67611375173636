import { FC, useState } from 'react';
import Image from 'next/image';
import { message, Popover } from 'antd';
import { useWindowSize } from '@shared/utils/hooks';
import breakpoint from '../../../../shared/assets/breakpoint.json';
import { ContentContainer, MainContainer, SmallSizeMainContainer, SocialWrapper, TitleContainer, WrapperShare } from './ShareButton.style';
interface Props {
  caption: string;
  path: string;
  size?: 'small';
}
const ShareButton: FC<Props> = ({
  caption,
  path,
  size
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const {
    width
  } = useWindowSize();
  const isMobile = width < breakpoint.md;
  const [open, setOpen] = useState(false);
  const url = `${process.env.WEB_FRONTEND_DOMAIN}${path}`;
  const socialList = [{
    name: 'Facebook',
    logo: '/images/home/banner/social/facebook.png',
    linkout: `https://www.facebook.com/dialog/share?app_id=${process.env.FACEBOOK_APP_ID}&display=popup&href=${url}&redirect_uri=${url}`
  }, {
    name: 'LINE',
    logo: '/images/home/banner/social/line.png',
    linkout: isMobile ? `https://line.me/R/share?text=${caption + ' ' + url}` : `https://social-plugins.line.me/lineit/share?url=${url}&text=${caption}`
  }];
  const renderContent = () => {
    return <ContentContainer>
                {socialList.map(({
        logo,
        name,
        linkout
      }) => <a href={linkout} target="_blank" aria-label={name} key={name}>
                        <SocialWrapper>
                            <Image src={logo} alt={`${name} logo`} width={32} height={32} />
                            <span className="text">{name}</span>
                        </SocialWrapper>
                    </a>)}
                <SocialWrapper onClick={() => {
        navigator.clipboard.writeText(url);
        messageApi.success('คัดลอกสำเร็จ');
      }}>
                    <Image src="/images/home/banner/social/link.png" alt="copy url icon" width={32} height={32} />
                    <span className="text">Copy URL</span>
                </SocialWrapper>
            </ContentContainer>;
  };
  const renderPopup = () => {
    return <Popover trigger="click" title={<TitleContainer>แชร์ไปยัง</TitleContainer>} content={renderContent()} open={open} placement="topRight" arrow={{
      pointAtCenter: true
    }} onOpenChange={() => setOpen(!open)}>
                <WrapperShare>
                    <Image src="/icons/share.svg" alt="share icon" width={24} height={24} />
                </WrapperShare>
            </Popover>;
  };
  return size == 'small' ? <SmallSizeMainContainer onClick={() => setOpen(true)}>
            {contextHolder}
            {renderPopup()}
        </SmallSizeMainContainer> : <MainContainer onClick={() => setOpen(true)}>
            {contextHolder}
            {renderPopup()}
        </MainContainer>;
};
export default ShareButton;