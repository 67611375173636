import { IGetSeoByPathUsecase } from '@modules/seo/domain/ports/getSeoByPath.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { ISeoRepository } from '../ports/seo.repository.port';
export class GetSeoByPathUsecase implements IGetSeoByPathUsecase {
  constructor(private readonly seoRepository: ISeoRepository) {}
  async handle(path: string): IGetSeoByPathUsecase.getSeoOutput {
    const currentPage = path === 'home' ? path : path.substring(1);
    const result = await this.seoRepository.getSeoByPath(currentPage);
    const value = result.value;
    if (result.isLeft()) return left(value);
    return right(value);
  }
}