import styled from 'styled-components';
export const MainContainer = styled.div.withConfig({
  displayName: "ShareButtonstyle__MainContainer",
  componentId: "sc-1ci14y0-0"
})(["", " &:hover{", "}@media (max-width:", "){", " &:hover{", "}}"], {
  "display": "flex",
  "height": "3rem",
  "width": "3rem",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "center",
  "overflow": "hidden",
  "borderRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity))",
  "padding": "0px",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "--tw-border-opacity": "1 !important",
  "borderColor": "rgb(206 14 45 / var(--tw-border-opacity)) !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "height": "2.5rem",
  "width": "2.5rem"
}, {
  "--tw-border-opacity": "1 !important",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity)) !important"
});
export const WrapperShare = styled.div.withConfig({
  displayName: "ShareButtonstyle__WrapperShare",
  componentId: "sc-1ci14y0-1"
})(["", ""], {
  "display": "flex",
  "height": "100%",
  "width": "100%",
  "alignItems": "center",
  "justifyContent": "center"
});
export const TitleContainer = styled.div.withConfig({
  displayName: "ShareButtonstyle__TitleContainer",
  componentId: "sc-1ci14y0-2"
})(["", ""], {
  "paddingTop": "0.5rem",
  "textAlign": "center",
  "fontSize": "1rem",
  "lineHeight": "18px",
  "fontWeight": "500",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
});
export const ContentContainer = styled.div.withConfig({
  displayName: "ShareButtonstyle__ContentContainer",
  componentId: "sc-1ci14y0-3"
})(["", ""], {
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "1rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "0.5rem"
});
export const SocialWrapper = styled.div.withConfig({
  displayName: "ShareButtonstyle__SocialWrapper",
  componentId: "sc-1ci14y0-4"
})(["", " img{", "}.text{", "}"], {
  "display": "flex",
  "cursor": "pointer",
  "flexDirection": "column",
  "alignItems": "center",
  "gap": "0.5rem"
}, {
  "height": "2rem",
  "width": "2rem",
  "borderRadius": "9999px",
  "objectFit": "cover"
}, {
  "textAlign": "center",
  "fontSize": "1rem",
  "lineHeight": "18px",
  "fontWeight": "300",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
});
export const SmallSizeMainContainer = styled.div.withConfig({
  displayName: "ShareButtonstyle__SmallSizeMainContainer",
  componentId: "sc-1ci14y0-5"
})(["", " img{", "}&:hover{", "}@media (max-width:", "){&:hover{", "}}"], {
  "display": "flex",
  "height": "2rem",
  "width": "2rem",
  "cursor": "pointer",
  "alignItems": "center",
  "justifyContent": "center",
  "overflow": "hidden",
  "borderRadius": "0.5rem",
  "borderWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity))",
  "padding": "0px",
  "transitionProperty": "all",
  "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
  "transitionDuration": "150ms"
}, {
  "width": "1.25rem"
}, {
  "--tw-border-opacity": "1 !important",
  "borderColor": "rgb(206 14 45 / var(--tw-border-opacity)) !important"
}, ({
  theme
}) => theme.breakpoint.md, {
  "--tw-border-opacity": "1 !important",
  "borderColor": "rgb(206 206 206 / var(--tw-border-opacity)) !important"
});