import { FeedSeoResponse } from '@modules/libs/graphql/generated/graphql';
import { ISeo } from '@shared/models/seo.model';
import { defaultSeo } from '../stores/seo.store';
export class SeoEntityMapper {
  public static toDomain({
    title,
    description,
    image,
    keywords
  }: FeedSeoResponse): ISeo {
    return {
      title: title || defaultSeo.title,
      description: description || defaultSeo.description,
      image: image || defaultSeo.image,
      keywords: keywords || defaultSeo.keywords
    };
  }
}