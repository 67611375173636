import { SeoDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { ISeoRepository } from '@modules/seo/application/ports/seo.repository.port';
import { left, right } from '@shared/utils/either.utils';
import { SeoEntityMapper } from '../../outbound/ports/seo.mapper';
import { defaultSeo } from '../../outbound/stores/seo.store';
export class SeoRepositoryImpl implements ISeoRepository {
  async getSeoByPath(path: string): ISeoRepository.getSeoOutput {
    try {
      const result = await gqlQuery({
        query: SeoDocument,
        variables: {
          data: {
            path
          }
        }
      });
      if (result) {
        return right(SeoEntityMapper.toDomain(result?.data?.getFeedSEOByPath));
      } else {
        return right(defaultSeo);
      }
    } catch (error: any) {
      console.error('error SeoRepositoryImpl', error);
      return left(error);
    }
  }
}