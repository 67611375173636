import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { OutlineButton } from '@components/button';
import { Container, ContentDetail, ContentTitle, GroupButton, WrapperButton, WrapperContent, WrapperImage } from './NotFound404.style';
const NotFound404: FC = () => {
  return <Container>
            <WrapperImage>
                <Image src="/images/404-logo.png" alt="Not Found 404" width={392} height={243} />
            </WrapperImage>

            <WrapperContent>
                <ContentTitle>
                    อุ๊ปส์! เราไม่พบสิ่งที่คุณกำลังมองหา
                </ContentTitle>
                <ContentDetail>
                    <p>ไม่ต้องกังวล เราจะพาคุณไปค้นพบสิ่งที่คุณต้องการ</p>
                    <p>
                        ลองค้นหาอีกครั้ง
                        หรือใช้ลิงก์ด้านล่างเพื่อค้นพบสิ่งที่คุณกำลังมองหา
                    </p>
                </ContentDetail>
            </WrapperContent>

            <WrapperButton>
                <GroupButton>
                    <Link href="/">
                        <OutlineButton>กลับหน้าหลัก</OutlineButton>
                    </Link>

                    <Link href="/recipe">
                        <OutlineButton>เมนูอาหาร</OutlineButton>
                    </Link>
                </GroupButton>

                <GroupButton>
                    <Link href="/product">
                        <OutlineButton>ผลิตภัณฑ์ซีพี</OutlineButton>
                    </Link>

                    <Link href="/tips-tricks">
                        <OutlineButton>เคล็ดลับ / บทความ</OutlineButton>
                    </Link>
                </GroupButton>
            </WrapperButton>
        </Container>;
};
export default NotFound404;