import { create } from 'zustand';
import { GetSeoByPathUsecase } from '@modules/seo/application/usecase/getSeoByPath.usecase';
import { ISeo } from '@shared/models/seo.model';
import { SeoRepositoryImpl } from '../../inbound/repositories/seo.repository';
interface SeoState {
  seo: ISeo;
  fetchSeo: (path: string) => void;
  resetSeo: () => void;
}
export const defaultSeo: ISeo = {
  title: 'เนื้อหมูสด เนื้อไก่สด และอาหารปลอดภัยจาก CP',
  description: 'เลือกซื้อ เนื้อหมูสด เนื้อไก่สด และอาหารสดใหม่ CP มาตรฐานความปลอดภัยไร้สารปนเปื้อน สะอาด ถูกสุขอนามัยบริโภคปลอดภัย ใช้ประกอบอาหาร มีคุณค่าทางโภชนาการ',
  image: `${process.env.WEB_FRONTEND_DOMAIN}/images/seo/default-web-og-image.jpg`,
  keywords: 'กินอะไรดี, อร่อยง่าย, อร่อยเพื่อสุขภาพ, เมนูอาหาร, เนื้อหมูสด, เนื้อไก่สด, ต้ม, แกง, สลัด, ยำ, ผัด, ทอด, เบเกอรี่, ขนม, เมนูแนะนำ, สูตรอาหาร'
};
export const defaultGameSeo: ISeo = {
  title: 'CP City Game: ปรุงเมนูสุดครีเอทีฟกับ CP! เกมทำอาหารฟรี',
  description: 'เกมทำอาหารสำหรับทุกคนที่อยากเรียนรู้สูตรอาหารอร่อยๆ จาก CP พร้อมสนุกไปกับการรวบรวมวัตถุดิบสุดพิเศษและปรุงอาหารเอง ลองเล่นเลย!',
  image: `${process.env.WEB_FRONTEND_DOMAIN}/images/seo/default-web-og-image.jpg`,
  keywords: 'เล่นเกมฟรี, เกมอินเตอร์เน็ต, เกมทำอาหารออนไลน์, เกมออนไลน์, เกมบนเว็บ'
};
export const seoStore = create<SeoState>(set => ({
  seo: defaultSeo,
  fetchSeo: async (path: string) => {
    try {
      const seoRepositoryImpl = new SeoRepositoryImpl();
      const getSeoByPathUsecase = new GetSeoByPathUsecase(seoRepositoryImpl);
      const result = await getSeoByPathUsecase.handle(path);
      if (result.isRight()) {
        set(() => ({
          seo: result.value
        }));
      }
    } catch (err) {
      console.error('error fetchSeo', err);
    }
  },
  resetSeo: () => {
    set(() => ({
      seo: defaultSeo
    }));
  }
}));