import { ReactNode } from 'react';
import { Container } from './OutlineButton.style';
interface Props {
  children?: ReactNode;
}
const OutlineButton = ({
  children
}: Props) => {
  return <Container>{children}</Container>;
};
export default OutlineButton;