import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "NotFound404style__Container",
  componentId: "sc-1se1rub-0"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "69px",
  "paddingTop": "32px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "paddingBottom": "79px",
  "paddingTop": "48px"
});
export const WrapperImage = styled.div.withConfig({
  displayName: "NotFound404style__WrapperImage",
  componentId: "sc-1se1rub-1"
})(["img{", "}@media (max-width:", "){img{", "}}"], {
  "width": "392px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "width": "236px"
});
export const WrapperContent = styled.div.withConfig({
  displayName: "NotFound404style__WrapperContent",
  componentId: "sc-1se1rub-2"
})(["", " p{", "}@media (max-width:", "){", "}"], {
  "marginBottom": "38px",
  "marginTop": "21px",
  "display": "flex",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "gap": "0.5rem"
}, {
  "textAlign": "center"
}, ({
  theme
}) => theme.breakpoint.md, {
  "marginTop": "2.5rem",
  "marginBottom": "2.5rem"
});
export const ContentTitle = styled.div.withConfig({
  displayName: "NotFound404style__ContentTitle",
  componentId: "sc-1se1rub-3"
})(["", " @media (max-width:", "){", "}"], {
  "fontSize": "45px",
  "fontWeight": "700",
  "lineHeight": "50px",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "fontSize": "28px",
  "lineHeight": "30px"
});
export const ContentDetail = styled.div.withConfig({
  displayName: "NotFound404style__ContentDetail",
  componentId: "sc-1se1rub-4"
})(["", " @media (max-width:", "){", "}"], {
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "fontWeight": "400",
  "--tw-text-opacity": "1",
  "color": "rgb(88 88 88 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.md, {
  "fontSize": "21px",
  "lineHeight": "1.5rem"
});
export const WrapperButton = styled.div.withConfig({
  displayName: "NotFound404style__WrapperButton",
  componentId: "sc-1se1rub-5"
})(["", " a{", "}@media (max-width:", "){", "}"], {
  "display": "flex",
  "flexDirection": "row",
  "gap": "22px"
}, {
  "borderRadius": "100px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "flexWrap": "wrap",
  "justifyContent": "center",
  "gap": "0.5rem"
});
export const GroupButton = styled.div.withConfig({
  displayName: "NotFound404style__GroupButton",
  componentId: "sc-1se1rub-6"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "gap": "22px"
}, ({
  theme
}) => theme.breakpoint.md, {
  "flexWrap": "wrap",
  "justifyContent": "center",
  "gap": "0.5rem"
});